import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/product/data-platform/meta.png'
import tag from '../../assets/images/tags/data-platform-tag.svg'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/data-platform/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/product/data-platform/lead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/integrations.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/pr-filters.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/issue-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/product/data-platform/data-cloud.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    blurb2: file(
      relativePath: { eq: "images/product/data-platform/exports-api.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 551)
      }
    }
  }
`

const DataPlatformPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Data Platform: Software engineering data you can trust"
      variant="dark"
      isNew
      description="Your decisions are only as good as the data that informs them. Swarmia allows you to reliably measure, visualize, and export your software development data."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Software engineering data&nbsp;you&nbsp;can trust</>}
        content="Your decisions are only as good as the data that informs them. Swarmia allows you to reliably measure, visualize, and export your software development data."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        heading={<>When it comes to data, quality&nbsp;is&nbsp;everything</>}
        content="Your data evolves constantly. Swarmia helps you keep it in sync, while giving you full control over what’s included in each metric."
      />
      <MosaicBlock
        title={<>Consistent</>}
        content="Swarmia pulls data from multiple sources and makes it easy for you to keep it consistent with the help of smart automation, syncing, linking, and categorization."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="Configurable"
        content={
          <>
            Many tools get your data right once during onboarding and expect it
            to stay in check afterward. The data quickly becomes outdated and
            unusable.
            <br />
            <br />
            With Swarmia, you can make small changes to your setup as your data
            evolves. This way, we ensure continuous data quality with minimal
            effort.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>Explainable</>}
        content="It’s hard to make informed decisions when your data lives in a black box. With Swarmia, you can always drill down to see what an aggregate number consists of."
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
      />
      <QuoteBlock person="martin" />
      <LeadBlock
        heading={<>Bring your data where&nbsp;you&nbsp;need&nbsp;it</>}
        content="Keeping all engineering data in sync and available in different formats is not an easy feat. Swarmia does the heavy-lifting, so you always have granular, real-time data ready for custom reporting and analysis."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(92)}
        columns={[
          <Blurb
            key="1"
            title="Data Cloud"
            text="Schedule data exports to push selected metrics to any data warehouse, including BigQuery, Snowflake, and Redshift."
            image={getImage(data.blurb1)}
            isLarge
          />,
          <Blurb
            key="2"
            title="Export API"
            text="Pull the data you need with Swarmia’s Export API and combine it with any other internal data."
            image={getImage(data.blurb2)}
            isLarge
          />,
        ]}
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title={
            <>
              Start using software engineering&nbsp;data&nbsp;you&nbsp;can trust
            </>
          }
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/engineering-benchmarks/',
            '/blog/measuring-software-development-productivity/',
            '/blog/continuous-integration/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}
export default DataPlatformPage
